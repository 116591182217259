import * as React from "react"
import { useState, useEffect, useRef } from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { Cross2Icon } from "@radix-ui/react-icons"

import { cn } from "@/lib/utils"

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

// Create a more detailed Context to share between Dialog components
const DialogScrollableContext = React.createContext({
  isScrollable: false,
  isScrolledFromTop: false,
  isScrolledToBottom: true,
  setScrollState: (state: { 
    isScrollable: boolean, 
    isScrolledFromTop: boolean, 
    isScrolledToBottom: boolean 
  }) => {}
})

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => {
  const [scrollState, setScrollState] = useState({
    isScrollable: false,
    isScrolledFromTop: false,
    isScrolledToBottom: true
  })
  
  return (
    <DialogPortal>
      <DialogOverlay />
      <DialogScrollableContext.Provider value={{ 
        ...scrollState, 
        setScrollState 
      }}>
        <DialogPrimitive.Content
          ref={ref}
          className={cn(
            "fixed left-[50%] top-[50%] z-50 w-full max-w-lg translate-x-[-50%] translate-y-[-50%] border bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg max-h-[calc(100vh-64px)] flex flex-col overflow-hidden",
            className
          )}
          {...props}
        >
          {children}
          <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <Cross2Icon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogPrimitive.Close>
        </DialogPrimitive.Content>
      </DialogScrollableContext.Provider>
    </DialogPortal>
  )
})
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  const { isScrollable, isScrolledFromTop } = React.useContext(DialogScrollableContext)
  
  return (
    <div
      className={cn(
        "flex flex-col space-y-1.5 text-center sm:text-left p-6 pb-3 shrink-0",
        isScrollable && isScrolledFromTop && "border-b border-border",
        className
      )}
      {...props}
    />
  )
}
DialogHeader.displayName = "DialogHeader"

const DialogBody = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  const bodyRef = useRef<HTMLDivElement>(null)
  const { setScrollState } = React.useContext(DialogScrollableContext)
  
  // Function to check scroll status and update context
  const updateScrollState = () => {
    if (bodyRef.current) {
      const element = bodyRef.current
      const hasScroll = element.scrollHeight > element.clientHeight
      const isScrolledDown = element.scrollTop > 0
      
      // Calculate if scrolled to bottom
      const isAtBottom = Math.abs(
        (element.scrollHeight - element.scrollTop) - element.clientHeight
      ) < 2 // Using a small threshold to account for rounding errors
      
      setScrollState({
        isScrollable: hasScroll,
        isScrolledFromTop: isScrolledDown,
        isScrolledToBottom: !hasScroll || isAtBottom
      })
    }
  }
  
  useEffect(() => {
    // Initial check
    updateScrollState()
    
    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      updateScrollState()
    })
    
    if (bodyRef.current) {
      resizeObserver.observe(bodyRef.current)
    }
    
    // Set up MutationObserver to detect content changes
    const mutationObserver = new MutationObserver(() => {
      updateScrollState()
    })
    
    if (bodyRef.current) {
      mutationObserver.observe(bodyRef.current, {
        childList: true,
        subtree: true,
        characterData: true
      })
    }
    
    // Check again after images and other resources load
    window.addEventListener('load', updateScrollState)
    
    // DialogContent open transition end check
    const timer = setTimeout(updateScrollState, 300)
    
    return () => {
      if (bodyRef.current) {
        resizeObserver.unobserve(bodyRef.current)
        mutationObserver.disconnect()
      }
      window.removeEventListener('load', updateScrollState)
      clearTimeout(timer)
    }
  }, [setScrollState])
  
  // Listen for scroll events
  useEffect(() => {
    const handleScroll = () => {
      updateScrollState()
    }
    
    const bodyElement = bodyRef.current
    if (bodyElement) {
      bodyElement.addEventListener('scroll', handleScroll, { passive: true })
    }
    
    return () => {
      if (bodyElement) {
        bodyElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [setScrollState])
  
  return (
    <div
      ref={bodyRef}
      className={cn(
        "flex-1 overflow-auto px-6 py-2 min-h-0",
        className
      )}
      {...props}
    />
  )
}
DialogBody.displayName = "DialogBody"

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  const { isScrollable, isScrolledToBottom } = React.useContext(DialogScrollableContext)
  
  return (
    <div
      className={cn(
        "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 p-6 pt-3 shrink-0",
        isScrollable && !isScrolledToBottom && "border-t border-border",
        className
      )}
      {...props}
    />
  )
}
DialogFooter.displayName = "DialogFooter"

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  DialogDescription,
}