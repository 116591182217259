import * as React from "react";
import { cn } from "@/lib/utils"

// Define variant-specific styles for keyboard shortcuts
const shortcutVariants = {
  default: "bg-gray-600 text-gray-200",
  outline: "bg-gray-100 group-hover:bg-gray-200 text-gray-600",
  secondary: "bg-gray-200 text-gray-600",
  destructive: "bg-red-100 text-red-700",
  ghost: "bg-gray-100 text-gray-600",
  link: "bg-gray-100 text-gray-600",
  slack: "bg-[#3A0E3C] text-white"
};

interface KeyboardShortcutProps {
  shortcutKey: string;
  className?: string;
  variant?: keyof typeof shortcutVariants;
}

/**
 * A consistent visual representation of a keyboard shortcut key
 */
export const KeyboardShortcut = React.forwardRef<
  HTMLSpanElement,
  KeyboardShortcutProps
>(({ shortcutKey, className, variant = 'default' }, ref) => {
  // Handle complex shortcut combinations
  const keys = shortcutKey
    .split('+')
    .map((key) => key.trim())
    .filter(Boolean);

  // Get the variant-specific class or fallback to default
  const variantClass = shortcutVariants[variant] || shortcutVariants.default;

  return (
    <span
      ref={ref}
      className={cn(
        "inline-flex items-center",
        className
      )}
      aria-hidden="true"
    >
      {keys.map((key, index) => (
        <React.Fragment key={key}>
          <kbd className={cn(
            "whitespace-nowrap min-w-5 h-5 px-1 inline-flex items-center justify-center rounded text-2xs font-mono font-regular uppercase transition-colors",
            variantClass
          )}>
            {key}
          </kbd>
          {index < keys.length - 1 && <span className="w-1" />}
        </React.Fragment>
      ))}
    </span>
  );
});

KeyboardShortcut.displayName = "KeyboardShortcut";