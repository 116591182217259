/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * AIActivity
 */
export interface AIActivity {
  /**
   * Id
   */
  id: string;

  /**
   * Type
   */
  type: 'AI';

  /**
   * Title
   */
  title: string;

  /**
   * Description
   */
  description: string;

  /**
   * Items
   */
  items: AIItem[];

  /**
   * Timestamp
   *
   * @format date-time
   */
  timestamp: string;
}

/**
 * AIItem
 */
export interface AIItem {
  /**
   * Id
   */
  id: string;

  /**
   * Title
   */
  title: string;

  /**
   * Description
   */
  description: string;
}

/**
 * AccessRequestDisplay
 */
export interface AccessRequestDisplay {
  /**
   * Id
   */
  id: string;

  /**
   * Contact Name
   */
  contact_name: string;

  /**
   * Company Name
   */
  company_name: string;

  /**
   * Email Address
   */
  email_address: string;

  /**
   * Position
   */
  position: string;

  reason: ContactReason;

  /**
   * Organization Name
   */
  organization_name: string;

  /**
   * Has Been Viewed
   */
  has_been_viewed: boolean;

  /**
   * Created At
   *
   * @format date-time
   */
  created_at: string;

  /**
   * Updated At
   *
   * @format date-time
   */
  updated_at: string;

  status: AccessRequestStatus;

  approver: PartialUser | null;
}

/**
 * AccessRequestStatus
 */
export enum AccessRequestStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

/**
 * AccessTokenRequest
 */
export interface AccessTokenRequest {
  /**
   * Access Request Id
   */
  access_request_id: string;
}

/**
 * AccessTokenResponse
 */
export interface AccessTokenResponse {
  /**
   * Token
   */
  token: string;

  /**
   * Expiration
   */
  expiration: string;
}

/**
 * AnswerType
 */
export enum AnswerType {
  MAIN = 'MAIN',
  SECONDARY = 'SECONDARY',
}

/**
 * ApproveAnswerRequest
 */
export interface ApproveAnswerRequest {
  /**
   * Question Id
   *
   * @format uuid
   */
  question_id: string;

  /**
   * Approved
   */
  approved: boolean;
}

/**
 * ApproveAnswerResponse
 */
export interface ApproveAnswerResponse {
  /**
   * Message
   */
  message: string;

  review_status: ReviewStatus;
}

/**
 * AssignDocumentRequest
 */
export interface AssignDocumentRequest {
  /**
   * Assigned User Id
   */
  assigned_user_id?: string | null;
}

/**
 * AssignedActivity
 */
export interface AssignedActivity {
  /**
   * Id
   */
  id: string;

  /**
   * Type
   */
  type: 'Assigned';

  assignedBy: Person;

  assignedTo: Person | null;

  /**
   * Timestamp
   *
   * @format date-time
   */
  timestamp: string;
}

/**
 * Body_upload_file_for_autofill
 */
export interface BodyUploadFileForAutofill {
  /**
   * File
   *
   * @format binary
   */
  file: File;

  /**
   * Deal Size
   */
  deal_size?: number | null;

  /**
   * Deadline
   */
  deadline?: string | null;

  /**
   * Instruction
   */
  instruction?: string | null;
}

/**
 * ChannelHistoryRescanResponse
 */
export interface ChannelHistoryRescanResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Channel Id
   */
  channel_id: string;

  /**
   * Message
   */
  message: string;
}

/**
 * CommentActivity
 */
export interface CommentActivity {
  /**
   * Id
   */
  id: string;

  /**
   * Type
   */
  type: 'Comment';

  author: Person;

  /**
   * Content
   */
  content: string;

  /**
   * Peopletags
   */
  peopleTags: Person[];

  /**
   * Timestamp
   *
   * @format date-time
   */
  timestamp: string;
}

/**
 * CompanyContent
 */
export interface CompanyContent {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Company Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  company_url: string;

  /**
   * Company Name
   */
  company_name: string;

  /**
   * Company Fav Icon
   */
  company_fav_icon?: string | null;

  /**
   * Is Access Enabled
   */
  is_access_enabled: boolean;

  header: Header;

  /**
   * Resources
   */
  resources: ResourceModel[];

  /**
   * Sub Processors
   */
  sub_processors?: SubProcessor[] | null;

  ai_model_architecture: Section;

  ai_model_use: Section;

  data_use_training: Section;

  data_use_inference: Section;

  /**
   * Controls
   */
  controls: ControlCategory[];

  /**
   * Iso Controls
   */
  iso_controls?: ISOControlCategory[] | null;
}

/**
 * ContactReason
 */
export enum ContactReason {
  EXISTING_CUSTOMER = 'EXISTING_CUSTOMER',
  PROSPECTIVE_CUSTOMER = 'PROSPECTIVE_CUSTOMER',
  OTHER = 'OTHER',
}

/**
 * ContentSourceType
 */
export enum ContentSourceType {
  MANUAL = 'MANUAL',
  CORE_KNOWLEDGE = 'CORE_KNOWLEDGE',
  WEBSITE_INTEGRATION = 'WEBSITE_INTEGRATION',
  VANTA_INTEGRATION = 'VANTA_INTEGRATION',
  EMAIL_INTEGRATION = 'EMAIL_INTEGRATION',
  SLACK_INTEGRATION = 'SLACK_INTEGRATION',
  GONG_INTEGRATION = 'GONG_INTEGRATION',
  NOTION_INTEGRATION = 'NOTION_INTEGRATION',
}

/**
 * ContentStatus
 */
export enum ContentStatus {
  YET_TO_START = 'YET_TO_START',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  SKIPPED = 'SKIPPED',
  FAILED = 'FAILED',
}

/**
 * ControlCategory
 */
export interface ControlCategory {
  /**
   * Category
   */
  category: string;

  /**
   * Items
   */
  items: ControlItem[];

  /**
   * Is Ai Specific
   */
  is_ai_specific: boolean;
}

/**
 * ControlItem
 */
export interface ControlItem {
  /**
   * Name
   */
  name: string;

  /**
   * Summary
   */
  summary: string;

  /**
   * Status
   */
  status: boolean;
}

/**
 * ConversationAnalytics
 */
export interface ConversationAnalytics {
  /**
   * Total Conversations
   */
  total_conversations: number;

  /**
   * Unique Users
   */
  unique_users: number;

  /**
   * Total Questionnaires
   */
  total_questionnaires: number;

  /**
   * Total Questions
   */
  total_questions: number;

  /**
   * Average Questions Per Questionnaire
   */
  average_questions_per_questionnaire: number;

  /**
   * Time Series Data
   */
  time_series_data: object[];

  /**
   * Users
   */
  users: UserConversationStats[];
}

/**
 * ConversationLogItem
 */
export interface ConversationLogItem {
  /**
   * Id
   */
  id: string;

  /**
   * Question
   */
  question: string;

  /**
   * Answer
   */
  answer: string;

  /**
   * User Name
   */
  user_name: string | null;

  /**
   * User Email
   */
  user_email: string | null;

  /**
   * User Profile Image
   */
  user_profile_image: string | null;

  /**
   * Source Type
   */
  source_type: string;

  /**
   * Answered At
   *
   * @format date-time
   */
  answered_at: string;
}

/**
 * ConversationResponse
 */
export interface ConversationResponse {
  /**
   * Agent Name
   */
  agent_name: string;

  /**
   * Messages
   */
  messages: MessageResponse[];
}

/**
 * CreateCommentRequest
 */
export interface CreateCommentRequest {
  /**
   * Content
   */
  content: string;

  /**
   * Peopletags
   */
  peopleTags?: string[] | null;
}

/**
 * DQDocumentResponse
 */
export interface DQDocumentResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * User Name
   */
  user_name: string;

  /**
   * User Email
   */
  user_email: string;

  /**
   * User Profile Image
   */
  user_profile_image: string | null;

  /**
   * Download Url
   */
  download_url: string;

  status: DQDocumentStatus;

  document_type: DQDocumentType;

  source_type: DQDocumentSourceType;

  /**
   * Num Of Questions
   */
  num_of_questions: number;

  /**
   * Answered Questions
   */
  answered_questions: number;

  /**
   * Created At
   *
   * @format date-time
   */
  created_at: string;

  /**
   * Deadline
   */
  deadline: string | null;

  /**
   * Deal Size
   */
  deal_size: number | null;
}

/**
 * DQDocumentSourceType
 */
export enum DQDocumentSourceType {
  FILE = 'FILE',
  URL = 'URL',
}

/**
 * DQDocumentStatus
 */
export enum DQDocumentStatus {
  YET_TO_START = 'YET_TO_START',
  QUESTION_EXTRACTION_IN_PROGRESS = 'QUESTION_EXTRACTION_IN_PROGRESS',
  QUESTION_EXTRACTION_COMPLETED = 'QUESTION_EXTRACTION_COMPLETED',
  QUESTION_EXTRACTION_FAILED = 'QUESTION_EXTRACTION_FAILED',
  ANSWERING_IN_PROGRESS = 'ANSWERING_IN_PROGRESS',
  ANSWERING_COMPLETED = 'ANSWERING_COMPLETED',
  USER_ACCEPTED_ANSWER = 'USER_ACCEPTED_ANSWER',
  ANSWERING_FAILED = 'ANSWERING_FAILED',
  MARKED_AS_DELETED = 'MARKED_AS_DELETED',
}

/**
 * DQDocumentType
 */
export enum DQDocumentType {
  RFI = 'RFI',
  RFP = 'RFP',
  SECURITY_QUESTIONNAIRE = 'SECURITY_QUESTIONNAIRE',
  UNCATEGORIZED = 'UNCATEGORIZED',
}

/**
 * DQDocumentsResponse
 */
export interface DQDocumentsResponse {
  /**
   * Documents
   */
  documents: DQDocumentResponse[];

  pagination: PaginationInfo;
}

/**
 * EvaluationData
 */
export interface EvaluationData {
  /**
   * Id
   */
  id: string;

  /**
   * Created At
   */
  created_at: string;

  /**
   * Average Score
   */
  average_score: string;

  /**
   * Accuracy
   */
  accuracy: number;

  /**
   * Clarity
   */
  clarity: number;

  /**
   * Completeness
   */
  completeness: number;

  /**
   * Latency
   */
  latency: number;
}

/**
 * ExtensionTokenResponse
 */
export interface ExtensionTokenResponse {
  /**
   * Access Token
   */
  access_token: string;

  /**
   * Token Type
   *
   * @default "bearer"
   */
  token_type?: string;
}

/**
 * FactReference
 */
export interface FactReference {
  /**
   * Statement
   */
  statement: string;

  source_type: ContentSourceType;

  /**
   * Source Name
   */
  source_name: string;

  /**
   * Last Modified
   */
  last_modified: string;
}

/**
 * FactResponse
 */
export interface FactResponse {
  /**
   * Id
   *
   * @format uuid
   */
  id: string;

  /**
   * Content Source Id
   */
  content_source_id?: string | null;

  /**
   * Statement
   */
  statement: string;

  /**
   * Source Last Modified
   */
  source_last_modified?: string | null;

  /**
   * Source Download Url
   */
  source_download_url?: string | null;

  /**
   * Source Name
   */
  source_name?: string | null;

  source_type?: ContentSourceType | null;

  status: FactStatus;

  /**
   * Created At
   *
   * @format date-time
   */
  created_at: string;
}

/**
 * FactStatus
 */
export enum FactStatus {
  ACTIVE = 'ACTIVE',
  IN_REVIEW = 'IN_REVIEW',
  DEPRECATED = 'DEPRECATED',
}

/**
 * FeaturesCapabilities
 */
export interface FeaturesCapabilities {
  /**
   * Has Installed Slack App
   *
   * Whether the organization has Wolfia app installed on Slack
   * @default false
   */
  has_installed_slack_app?: boolean;

  /**
   * Has Trust Center
   *
   * Whether the organization has a trust center configured
   * @default false
   */
  has_trust_center?: boolean;

  /**
   * Show Insights
   *
   * Enable insights dashboard for admin users to track question analytics
   * @default false
   */
  show_insights?: boolean;
}

/**
 * HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   * Detail
   */
  detail?: ValidationError[];
}

/**
 * Header
 */
export interface Header {
  company_logo: Logo;

  /**
   * Company Description
   */
  company_description: string;

  /**
   * Security Point Of Contact
   */
  security_point_of_contact: string;

  /**
   * Security Email
   */
  security_email: string;

  /**
   * Privacy Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  privacy_url: string;

  /**
   * Terms Of Service Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  terms_of_service_url: string;

  appearance: HeaderAppearance;

  /**
   * Customer Logo Urls
   */
  customer_logo_urls?: Logo[] | null;

  /**
   * Compliance
   */
  compliance?: Logo[] | null;
}

/**
 * HeaderAppearance
 */
export interface HeaderAppearance {
  theme: Theme;

  /**
   * Gradient Start
   */
  gradient_start: string;

  /**
   * Gradient End
   */
  gradient_end: string;
}

/**
 * ISOControlCategory
 */
export interface ISOControlCategory {
  /**
   * Category
   */
  category: string;

  /**
   * Summary
   */
  summary: string;

  /**
   * Items
   */
  items: ISOControlItem[];
}

/**
 * ISOControlItem
 */
export interface ISOControlItem {
  /**
   * Criteria
   */
  criteria: string;

  /**
   * Topic
   */
  topic: string;

  /**
   * Control
   */
  control: string;

  /**
   * Resources
   */
  resources: string[];

  /**
   * Status
   */
  status: boolean;
}

/**
 * IngestedContentType
 */
export enum IngestedContentType {
  PRODUCT_ENGINEERING = 'PRODUCT_ENGINEERING',
  SALES_MARKETING = 'SALES_MARKETING',
  OPERATIONS_COMPLIANCE = 'OPERATIONS_COMPLIANCE',
}

/**
 * IntegrationDetailResponse
 */
export interface IntegrationDetailResponse {
  integration_type: ContentSourceType;

  /**
   * Items
   */
  items: IntegrationItem[];

  pagination: PaginationInfo;
}

/**
 * IntegrationItem
 */
export interface IntegrationItem {
  /**
   * Id
   *
   * @format uuid
   */
  id: string;

  /**
   * Name
   */
  name: string;

  status: ContentStatus;

  ingested_content_type?: IngestedContentType | null;

  /**
   * Last Modified At
   *
   * @format date-time
   */
  last_modified_at: string;

  /**
   * Download Url
   */
  download_url?: string | null;
}

/**
 * IntegrationSummary
 */
export interface IntegrationSummary {
  integration_type: ContentSourceType;

  /**
   * Provider Name
   */
  provider_name: string;

  /**
   * Source Count
   */
  source_count: number;

  /**
   * Last Modified At
   */
  last_modified_at?: string | null;
}

/**
 * InviteUserRequest
 */
export interface InviteUserRequest {
  /**
   * Email
   *
   * @format email
   */
  email: string;
}

/**
 * ListItem
 */
export interface ListItem {
  /**
   * Description
   */
  description: string;

  /**
   * Is Supported
   */
  is_supported: boolean;
}

/**
 * ListOrganizationUsersResponse
 */
export interface ListOrganizationUsersResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Users
   */
  users: OrganizationUserResponse[];
}

/**
 * ListSlackChannelsResponse
 */
export interface ListSlackChannelsResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Channels
   */
  channels: SlackChannelResponse[];
}

/**
 * Logo
 */
export interface Logo {
  /**
   * Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string;

  /**
   * Alt
   */
  alt: string;

  /**
   * Height
   */
  height: number;

  /**
   * Width
   */
  width: number;
}

/**
 * MessageFeedbackRequest
 */
export interface MessageFeedbackRequest {
  /**
   * Feedback Positive
   */
  feedback_positive: boolean;

  /**
   * Feedback Comment
   */
  feedback_comment?: string | null;
}

/**
 * MessageRequest
 */
export interface MessageRequest {
  /**
   * Message
   */
  message: string;
}

/**
 * MessageResponse
 */
export interface MessageResponse {
  /**
   * Id
   *
   * @format uuid
   */
  id: string;

  /**
   * Content
   */
  content: string;

  /**
   * Sender Name
   */
  sender_name: string;

  /**
   * Created At
   *
   * @format date-time
   */
  created_at: string;

  /**
   * Is Ai Response
   */
  is_ai_response: boolean;

  /**
   * Confidence Score
   */
  confidence_score?: string | null;

  /**
   * Citations
   */
  citations?: Record<string, string | FactReference> | null;

  /**
   * Follow Ups
   */
  follow_ups?: string[] | null;

  /**
   * Feedback Positive
   */
  feedback_positive?: boolean | null;

  /**
   * Feedback Comment
   */
  feedback_comment?: string | null;

  /**
   * Feedback Timestamp
   */
  feedback_timestamp?: string | null;
}

/**
 * NotionOAuthCallbackResponse
 */
export interface NotionOAuthCallbackResponse {
  /**
   * Token Data
   */
  token_data: object;

  /**
   * Integration Id
   */
  integration_id: string;
}

/**
 * NotionSyncResponse
 */
export interface NotionSyncResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Message
   */
  message: string;
}

/**
 * OrganizationCreate
 */
export interface OrganizationCreate {
  /**
   * Name
   *
   * @minLength 1
   * @maxLength 100
   */
  name: string;

  /**
   * Tenant Name
   */
  tenant_name?: string | null;

  /**
   * Hd
   */
  hd?: string | null;

  /**
   * Logo Url
   */
  logo_url?: string | null;
}

/**
 * OrganizationEdit
 */
export interface OrganizationEdit {
  /**
   * Name
   */
  name?: string | null;

  /**
   * Tenant Name
   */
  tenant_name?: string | null;
}

/**
 * OrganizationResponse
 */
export interface OrganizationResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Tenant Name
   */
  tenant_name: string;

  /**
   * Hd
   */
  hd: string;

  plan_type: PlanType;

  /**
   * Is Trial
   */
  is_trial: boolean;

  /**
   * Logo Url
   */
  logo_url?: string | null;

  /**
   * Trial Start Date
   */
  trial_start_date?: string | null;

  /**
   * Trial End Date
   */
  trial_end_date?: string | null;
}

/**
 * OrganizationUserResponse
 */
export interface OrganizationUserResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Email
   */
  email: string;

  /**
   * Name
   */
  name: string;

  /**
   * Username
   */
  username: string;

  role: UserRole;

  /**
   * Is Internal
   */
  is_internal: boolean;

  /**
   * Profile Image
   */
  profile_image: string | null;
}

/**
 * OutputFormat
 */
export enum OutputFormat {
  Docx = 'docx',
  Original = 'original',
  Pdf = 'pdf',
  Xlsx = 'xlsx',
}

/**
 * PaginatedConversationLogs
 */
export interface PaginatedConversationLogs {
  /**
   * Data
   */
  data: ConversationLogItem[];

  /**
   * Total
   */
  total: number;

  /**
   * Page
   */
  page: number;

  /**
   * Page Size
   */
  page_size: number;

  /**
   * Total Pages
   */
  total_pages: number;
}

/**
 * PaginationInfo
 */
export interface PaginationInfo {
  /**
   * Total
   */
  total: number;

  /**
   * Limit
   */
  limit: number;

  /**
   * Offset
   */
  offset: number;

  /**
   * Has Next
   */
  has_next: boolean;
}

/**
 * PartialUser
 */
export interface PartialUser {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Profile Image
   */
  profile_image: string | null;

  /**
   * Username
   */
  username: string | null;
}

/**
 * Person
 */
export interface Person {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Email
   */
  email: string;

  /**
   * Profileimage
   */
  profileImage: string | null;
}

/**
 * PlanType
 */
export enum PlanType {
  FREE = 'FREE',
  STARTER = 'STARTER',
  PRO = 'PRO',
  ENTERPRISE = 'ENTERPRISE',
}

/**
 * PortalAccessRequest
 */
export interface PortalAccessRequest {
  /**
   * Contact Name
   */
  contact_name: string;

  /**
   * Company Name
   */
  company_name?: string | null;

  /**
   * Position
   */
  position?: string | null;

  /**
   * Email Address
   *
   * @format email
   */
  email_address: string;

  reason: ContactReason;

  /**
   * Organization Id
   */
  organization_id: string;
}

/**
 * QuestionnaireAnswerDetailResponse
 */
export interface QuestionnaireAnswerDetailResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Answer
   */
  answer: string;

  answer_type: AnswerType;

  /**
   * Constraints
   */
  constraints?: string[] | null;

  /**
   * Confidence Score
   */
  confidence_score?: number | null;
}

/**
 * QuestionnaireOverviewResponse
 */
export interface QuestionnaireOverviewResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Status
   */
  status: string;

  /**
   * Document Type
   */
  document_type: string;

  /**
   * Download Url
   */
  download_url: string | null;

  source_type: DQDocumentSourceType;

  /**
   * Deadline
   */
  deadline: string | null;

  /**
   * Deal Value
   */
  deal_value: number | null;

  /**
   * Custom Instructions
   */
  custom_instructions: string | null;

  /**
   * Sheets
   */
  sheets: QuestionnaireSheetResponse[];
}

/**
 * QuestionnaireQuestionDetailResponse
 */
export interface QuestionnaireQuestionDetailResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Question
   */
  question: string;

  /**
   * Approved
   */
  approved: boolean;

  /**
   * Answers
   *
   * @default []
   */
  answers?: QuestionnaireAnswerDetailResponse[];

  /**
   * Facts
   */
  facts?: FactResponse[] | null;
}

/**
 * QuestionnaireQuestionOverviewResponse
 */
export interface QuestionnaireQuestionOverviewResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Question
   */
  question: string;

  /**
   * Main Answer
   */
  main_answer: string | null;

  /**
   * Approved
   */
  approved: boolean;

  /**
   * Needs Attention
   */
  needs_attention: boolean;

  /**
   * Confidence Score
   */
  confidence_score: number | null;
}

/**
 * QuestionnaireResponse
 */
export interface QuestionnaireResponse {
  /**
   * File Id
   */
  file_id: string;

  /**
   * Message
   */
  message: string;
}

/**
 * QuestionnaireSheetQuestionsResponse
 */
export interface QuestionnaireSheetQuestionsResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Number
   */
  number: number;

  /**
   * Questions
   */
  questions: QuestionnaireQuestionOverviewResponse[];

  /**
   * Total Questions
   */
  total_questions: number;

  /**
   * Limit
   */
  limit: number;

  /**
   * Offset
   */
  offset: number;
}

/**
 * QuestionnaireSheetResponse
 */
export interface QuestionnaireSheetResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Number
   */
  number: number;
}

/**
 * QuestionnaireURLRequest
 */
export interface QuestionnaireURLRequest {
  /**
   * Url
   */
  url: string;

  /**
   * Username
   */
  username?: string | null;

  /**
   * Password
   */
  password?: string | null;

  /**
   * Deal Size
   */
  deal_size?: number | null;

  /**
   * Deadline
   */
  deadline?: string | null;

  /**
   * Instruction
   */
  instruction?: string | null;
}

/**
 * RegenerateAnswersRequest
 */
export interface RegenerateAnswersRequest {
  /**
   * Instructions
   */
  instructions?: string | null;
}

/**
 * RenameDocumentRequest
 */
export interface RenameDocumentRequest {
  /**
   * Name
   */
  name: string;
}

/**
 * ReprocessChannelsRequest
 */
export interface ReprocessChannelsRequest {
  /**
   * Channel Ids
   */
  channel_ids?: string[] | null;

  /**
   * Reset Ingestion
   *
   * @default false
   */
  reset_ingestion?: boolean;
}

/**
 * ReprocessChannelsResponse
 */
export interface ReprocessChannelsResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Channels Queued
   */
  channels_queued: number;

  /**
   * Message
   */
  message: string;
}

/**
 * ReprocessContentSourceRequest
 */
export interface ReprocessContentSourceRequest {
  /**
   * Content Source Id
   */
  content_source_id: string;

  /**
   * Organization Id
   */
  organization_id: string;
}

/**
 * ReprocessContentSourcesByTypeRequest
 */
export interface ReprocessContentSourcesByTypeRequest {
  /**
   * Organization Id
   */
  organization_id: string;

  source_type: ContentSourceType;
}

/**
 * ResourceModel
 */
export interface ResourceModel {
  /**
   * Type
   */
  type: string;

  /**
   * Title
   */
  title: string;

  /**
   * Description
   */
  description: string;

  /**
   * Is Public
   */
  is_public: boolean;

  /**
   * Url
   */
  url?: string | null;
}

/**
 * Response
 */
export interface Response {
  /**
   * Message
   */
  message: string;
}

/**
 * ResumeAnswersRequest
 */
export interface ResumeAnswersRequest {
  /**
   * Instructions
   */
  instructions?: string | null;
}

/**
 * ReviewStatus
 */
export enum ReviewStatus {
  NOT_REVIEWED = 'NOT_REVIEWED',
  REVIEWED = 'REVIEWED',
}

/**
 * SearchFact
 */
export interface SearchFact {
  /**
   * Id
   *
   * @format uuid
   */
  id: string;

  /**
   * Statement
   */
  statement: string;

  /**
   * Keyword Score
   */
  keyword_score: number;

  /**
   * Semantic Score
   */
  semantic_score: number;

  /**
   * Fact Score
   */
  fact_score: number;
}

/**
 * SearchResponse
 */
export interface SearchResponse {
  /**
   * Results
   */
  results: SearchResult[];

  pagination: PaginationInfo;
}

/**
 * SearchResult
 */
export interface SearchResult {
  /**
   * Id
   *
   * @format uuid
   */
  id: string;

  /**
   * Display Name
   */
  display_name: string;

  source_type: ContentSourceType;

  status: ContentStatus;

  /**
   * Last Modified At
   *
   * @format date-time
   */
  last_modified_at: string;

  /**
   * Relevance Score
   */
  relevance_score: number;

  /**
   * Fact Count
   */
  fact_count: number;

  /**
   * Facts
   */
  facts: SearchFact[];
}

/**
 * Section
 */
export interface Section {
  /**
   * Title
   */
  title?: string | null;

  /**
   * Summary
   */
  summary?: string | null;

  /**
   * Logos
   *
   * @default []
   */
  logos?: Logo[];

  /**
   * Items
   *
   * @default []
   */
  items?: ListItem[];

  /**
   * Is Verified
   *
   * @default false
   */
  is_verified?: boolean;
}

/**
 * SlackChannelResponse
 */
export interface SlackChannelResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Channel Id
   */
  channel_id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Is Monitoring
   */
  is_monitoring: boolean;

  /**
   * Monitoring Enabled By
   */
  monitoring_enabled_by: string | null;

  /**
   * Monitoring Enabled At
   */
  monitoring_enabled_at: string | null;
}

/**
 * SlackChannelScanResponse
 */
export interface SlackChannelScanResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Message
   */
  message: string;
}

/**
 * SlackPreferencesResponse
 */
export interface SlackPreferencesResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Scan Slack Conversations
   */
  scan_slack_conversations: boolean;

  /**
   * Scan Slack Channels
   */
  scan_slack_channels: boolean;

  /**
   * Scan Slack Users
   */
  scan_slack_users: boolean;
}

/**
 * SlackUpdateHistoryResponse
 */
export interface SlackUpdateHistoryResponse {
  /**
   * Message
   */
  message: string;

  /**
   * Job Id
   */
  job_id: string;
}

/**
 * SlackUserScanResponse
 */
export interface SlackUserScanResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Message
   */
  message: string;
}

/**
 * Standard
 */
export enum Standard {
  ISO42001 = 'ISO-42001',
  NISTAI = 'NIST-AI',
  CCPA = 'CCPA',
  SOC2 = 'SOC2',
  ISO27001 = 'ISO-27001',
  OWASP = 'OWASP',
  GDPR = 'GDPR',
  HIPAA = 'HIPAA',
  FedRAMP = 'FedRAMP',
  PCIDSS = 'PCI-DSS',
}

/**
 * SubProcessor
 */
export interface SubProcessor {
  /**
   * Name
   */
  name: string;

  /**
   * Purpose
   */
  purpose: string;

  /**
   * Location
   */
  location: string[];

  logo: Logo;

  /**
   * Standards
   */
  standards?: Standard[] | null;
}

/**
 * SuggestedQuestionsResponse
 */
export interface SuggestedQuestionsResponse {
  /**
   * Questions
   */
  questions: string[];
}

/**
 * SwitchOrganizationRequest
 */
export interface SwitchOrganizationRequest {
  /**
   * Organization Id
   *
   * ID of the organization to switch to
   */
  organization_id: string;
}

/**
 * Theme
 */
export enum Theme {
  Light = 'light',
  Dark = 'dark',
}

/**
 * TimeFrame
 */
export enum TimeFrame {
  Month = 'month',
  Quarter = 'quarter',
  Halfyear = 'halfyear',
  Year = 'year',
  Alltime = 'alltime',
  Custom = 'custom',
}

/**
 * UpdateAnswerRequest
 */
export interface UpdateAnswerRequest {
  /**
   * Answer Text
   */
  answer_text: string;

  review_status?: ReviewStatus | null;
}

/**
 * UpdateAnswerResponse
 */
export interface UpdateAnswerResponse {
  /**
   * Message
   */
  message: string;

  /**
   * Current Answer
   */
  current_answer?: string | null;
}

/**
 * UpdateChannelMonitoringRequest
 */
export interface UpdateChannelMonitoringRequest {
  /**
   * Is Monitoring
   */
  is_monitoring: boolean;
}

/**
 * UpdateChannelMonitoringResponse
 */
export interface UpdateChannelMonitoringResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Channel Id
   */
  channel_id: string;

  /**
   * Is Monitoring
   */
  is_monitoring: boolean;

  /**
   * Monitoring Enabled By
   */
  monitoring_enabled_by: string | null;

  /**
   * Monitoring Enabled At
   */
  monitoring_enabled_at: string | null;

  /**
   * Message
   */
  message: string;
}

/**
 * UpdateCommentRequest
 */
export interface UpdateCommentRequest {
  /**
   * Content
   */
  content?: string | null;

  /**
   * Peopletags
   */
  peopleTags?: string[] | null;
}

/**
 * UpdateSlackPreferencesRequest
 */
export interface UpdateSlackPreferencesRequest {
  /**
   * Scan Slack Conversations
   */
  scan_slack_conversations: boolean;

  /**
   * Scan Slack Channels
   */
  scan_slack_channels: boolean;

  /**
   * Scan Slack Users
   */
  scan_slack_users: boolean;
}

/**
 * UpdateUserRoleRequest
 */
export interface UpdateUserRoleRequest {
  /**
   * User Id
   */
  user_id: string;

  role: UserRole;
}

/**
 * UpdateUserRoleResponse
 */
export interface UpdateUserRoleResponse {
  /**
   * User Id
   */
  user_id: string;

  role: UserRole;

  /**
   * Message
   */
  message: string;
}

/**
 * UpdatedActivity
 */
export interface UpdatedActivity {
  /**
   * Id
   */
  id: string;

  /**
   * Type
   */
  type: 'Updated';

  updatedBy: Person;

  /**
   * Updatedfield
   */
  updatedField: string;

  /**
   * Oldvalue
   */
  oldValue: null;

  /**
   * Newvalue
   */
  newValue: null;

  /**
   * Changesummary
   */
  changeSummary: null;

  /**
   * Timestamp
   *
   * @format date-time
   */
  timestamp: string;
}

/**
 * UploadContentRequest
 */
export interface UploadContentRequest {
  /**
   * Content
   */
  content: string;
}

/**
 * UploadedActivity
 */
export interface UploadedActivity {
  /**
   * Id
   */
  id: string;

  /**
   * Type
   */
  type: 'Uploaded';

  uploadedBy: Person;

  /**
   * Documentname
   */
  documentName: string;

  /**
   * Timestamp
   *
   * @format date-time
   */
  timestamp: string;
}

/**
 * UrlSelectionRequest
 */
export interface UrlSelectionRequest {
  /**
   * Job Id
   */
  job_id: string;

  /**
   * Selected Urls
   */
  selected_urls: string[];

  /**
   * Org Id
   */
  org_id?: string | null;

  /**
   * Original Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  original_url: string;
}

/**
 * UrlSelectionResponse
 */
export interface UrlSelectionResponse {
  /**
   * Status
   */
  status: string;

  /**
   * Message
   */
  message: string;

  /**
   * Urls
   */
  urls: string[];
}

/**
 * UserConversationStats
 */
export interface UserConversationStats {
  /**
   * Name
   */
  name: string;

  /**
   * Email
   */
  email: string;

  /**
   * Profile Image
   */
  profile_image: string | null;

  /**
   * Conversation Count
   */
  conversation_count: number;
}

/**
 * UserEdit
 */
export interface UserEdit {
  /**
   * Name
   */
  name?: string | null;

  /**
   * Email
   */
  email?: string | null;

  /**
   * Username
   */
  username?: string | null;
}

/**
 * UserResponse
 */
export interface UserResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Email
   */
  email: string;

  /**
   * Name
   */
  name: string;

  status: UserStatus;

  role: UserRole;

  /**
   * Is Internal
   */
  is_internal: boolean;

  capabilities: FeaturesCapabilities;

  organization?: OrganizationResponse | null;

  /**
   * Username
   */
  username?: string | null;

  /**
   * Profile Image
   */
  profile_image?: string | null;
}

/**
 * UserRole
 */
export enum UserRole {
  ADMIN = 'ADMIN',
  EXPERT = 'EXPERT',
  USER = 'USER',
}

/**
 * UserStatus
 */
export enum UserStatus {
  ONBOARDING_CREATE_ORG = 'ONBOARDING_CREATE_ORG',
  ONBOARDING_ADD_SOURCES = 'ONBOARDING_ADD_SOURCES',
  ONBOARDING_ASK_QUESTION = 'ONBOARDING_ASK_QUESTION',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

/**
 * ValidationError
 */
export interface ValidationError {
  /**
   * Location
   */
  loc: (string | number)[];

  /**
   * Message
   */
  msg: string;

  /**
   * Error Type
   */
  type: string;
}

/**
 * VantaIntegrationCreate
 */
export interface VantaIntegrationCreate {
  /**
   * Config
   */
  config: object;
}

/**
 * VantaIntegrationResponse
 */
export interface VantaIntegrationResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Config
   */
  config: object;

  /**
   * Last Synced At
   */
  last_synced_at: string | null;

  /**
   * Sync Status
   */
  sync_status: string | null;

  /**
   * Created At
   */
  created_at: string;

  /**
   * Updated At
   */
  updated_at: string;
}

/**
 * WebsiteCrawlRequest
 */
export interface WebsiteCrawlRequest {
  /**
   * Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string;

  /**
   * Search
   */
  search?: string | null;
}

/**
 * WebsiteCrawlResponse
 */
export interface WebsiteCrawlResponse {
  /**
   * Job Id
   */
  job_id: string;

  /**
   * Message
   */
  message: string;

  /**
   * Original Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  original_url: string;
}
